import React from 'react';
import styled from 'styled-components';
import ErrorMessage from '../components/common/ErrorMessage';

const ERROR = {
  title: 'Invalid URL',
  description: "The page you're trying to access does not exist.",
};

const ErrorPage = () => (
  <Container>
    <ErrorMessage {...ERROR} />
  </Container>
);

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex: 1;
`;

export default ErrorPage;
